import Action from '../Action';
import { signOut } from 'next-auth/client';

export const logoutAction = () => ({
	type: Action.LOGOUT,
});

export const logout = () => (dispatch) =>
	new Promise(async (resolve) => {
		await signOut();
		dispatch(logoutAction());
		resolve(true);
	});
