import moment from 'moment';
import Sentry from '../util/sentry';

const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;
const GOOGLE_API_KEY = process.env.NEXT_PUBLIC_FIREBASE_API_KEY;
const GOOGLE_CALENDAR_SCOPE =
	'https://www.googleapis.com/auth/calendar.readonly';

function _init() {
	if (typeof window === 'undefined') return;
	if (window.gapi && window.gapi.auth2)
		return window.gapi.auth2.init({
			clientId: GOOGLE_CLIENT_ID,
			apiKey: GOOGLE_API_KEY,
			scope: GOOGLE_CALENDAR_SCOPE,
		});
	else return Promise.reject('gapi not found');
}

export default class GoogleService {
	static async getCalendarSuggestions(
		email,
		authenticate,
		extensive = false,
		filterUsers = null
	) {
		if (authenticate) {
			const res = await GoogleService.checkAuth();
			if (!res) throw Error('authentication failed');
		}
		await window.gapi.client.load('calendar', 'v3');

		let attendeesList = [];
		let eventResponse;

		const filterEmails = filterUsers?.map((u) => u.email);

		eventResponse = await window.gapi.client.calendar.events.list({
			calendarId: email,
			timeMin: moment()
				.subtract(extensive ? 6 : 3, 'months')
				.format('YYYY-MM-DDTHH:mm:ssZ'),
			timeMax: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
		});

		const events = eventResponse.result.items || [];
		events.forEach((event) => {
			if ('attendees' in event) {
				event.attendees.forEach((attendee) => {
					if (
						attendee.email.includes('calendar.google.com') ||
						filterEmails?.includes(attendee.email) ||
						attendee.email.includes('team@') ||
						attendee.email.includes('hr@')
					) {
						return;
					}
					attendeesList.push({
						name: attendee.displayName,
						email: attendee.email,
					});
				});
			}
		});

		let result = [];
		const map = new Map();

		for (const item of attendeesList) {
			if (!map.has(item.email) && item.email !== email) {
				map.set(item.email, true);
				result.push({
					...item,
					count: 1,
				});
			} else {
				let index = result.findIndex((r) => r.email === item.email);
				if (index > -1) {
					result[index].count++;
				}
			}
		}

		return result.sort((a, b) => b.count - a.count);
	}

	/**
	 * Google Account Connection Functions
	 */
	static init() {
		try {
			_init();
		} catch (e) {
			Sentry.clientException(e);
		}
	}

	static checkAuth() {
		return new Promise(async (resolve, reject) => {
			await _init();

			const options = new window.gapi.auth2.SigninOptionsBuilder({
				scope: GOOGLE_CALENDAR_SCOPE,
			});

			const auth2 = window.gapi.auth2.getAuthInstance();

			if (auth2) {
				auth2.currentUser
					.get()
					.grant(options)
					.then((success) => resolve(success))
					.catch((e) => reject(e));
			} else {
				reject('authentication failed');
			}
		});
	}
}
