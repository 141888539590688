import axios from 'axios';

/**
 * Client Side Communication
 */
const axiosInstance = axios.create({
	headers: { 'Content-Type': 'application/json' },
	withCredentials: true,
});
export const http = axiosInstance;

http.interceptors.response.use((response) => response.data);

export const swrFetcher = (url) => {
	return http.get(url);
};
