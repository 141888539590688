import GoogleService from '../../services/GoogleService';
import Action from '../Action';

export const setCalendarSuggestions = (suggestions) => ({
	type: Action.SET_CALENDAR_SUGGESTIONS,
	suggestions,
});

export const getCalendarSuggestions = (email, authenticate, filterUsers) => (
	dispatch
) =>
	new Promise((resolve) => {
		if (!email) return resolve(true);

		dispatch(setSuggestionsFetching(false));
		GoogleService.getCalendarSuggestions(email, authenticate, true, filterUsers)
			.then((suggestions) => {
				dispatch(setCalendarSuggestions(suggestions));
				resolve(suggestions);
			})
			.catch(() => resolve(false))
			.finally(() => dispatch(setSuggestionsFetching(false)));
	});

export const setSuggestionsFetching = (fetching) => ({
	type: Action.SET_SUGGESTIONS_FETCHING,
	fetching,
});
