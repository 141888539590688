const Action = {
	/** System */
	LOGOUT: 'LOGOUT',

	/** Calendar Suggestions */
	SET_CALENDAR_SUGGESTIONS: 'SET_CALENDAR_SUGGESTIONS',
	SET_SUGGESTIONS_FETCHING: 'SET_SUGGESTIONS_FETCHING',
};

export default Action;
